<template>
  <v-row v-scroll="onScroll">
    <!-- header -->
    <mini-header title="Segmentos" :breadcrumbs="breadcrumbs" :tooltip="`A este grupo pertenecen todos los proveedores de ${breadcrumbs.children[1].text1}`" :dense="true" :hiddenTab="true" :scroll="scroll">
      <template v-slot:main>
        <v-tooltip color="bgSearch" right max-width="185px" transition="scale-transition" open-delay="1000">
          <template v-slot:activator="{ on }">
            <span class="fontBody--text body-1" v-on="on">
              <v-btn class="ml-2" width="35" outlined @click="mode = !mode">
              <v-icon size="19">mdi-{{ !mode ? 'view-grid-outline' : 'table'}}</v-icon>
            </v-btn>
            </span>
          </template>
          <span class="d-block px-3 py-2">{{ mode ? 'Ver modo tabla' : 'Ver modo grilla' }}</span>
        </v-tooltip>
        <v-query-builder class="mx-2" :query-map="filters" model="suppliers" :active.sync="activeFilter" />
      </template>
    </mini-header>
    <!-- end header -->

    <v-col cols="12" class="px-0">
      <skeleton-list v-if="loading" />
      <template v-else>
        <v-empty-state v-if="!suppliersList.length" type="suppliers" id="proveedores" customClass="mt-5" :hiddenDescription="true" :isFree="true" title="No existen proveedores asociados al segmento">
        </v-empty-state>
        <template v-else>
          <v-data-table
            v-if="!mode"
            :class="[{'fixed-table' : $store.state.base.isExpandNavigationDrawer}]"
            :headers="headers"
            :items="suppliersList"
            :items-per-page="pagination"
            mobile-breakpoint
            hide-default-header
            disable-sort
            hide-default-footer
            item-key="id"
            @click:row="(suppliersList) => {$router.push({name: 'SupplierRetrieveUpdate', params: {id: suppliersList.id}})}"
            style="cursor: pointer"
            :height="heightTable"
            fixed-header
          >
            <!-- header -->
            <template v-slot:header="{ props: { headers } }">
              <VTableHeaders
                :headers="headers"
                @sort="setSort"
              />
              <v-divider style="position: absolute;  margin-top: -6px; margin-left: -5px ;z-index: 2; min-width: 100%" />
            </template>
            <!-- end header -->
            <template v-slot:[`item.name`]="{item}">
              <span class="text--secondary font-weight-medium">{{item.name}}</span>
            </template>
            <template v-slot:[`item.tax_settings`]="{item}">
              <template v-if="item.tax_settings?.cl_activity_description">{{item.tax_settings?.cl_activity_description}}</template>
              <span class="body-2 text--disabled" v-else>Sin especificar</span>
            </template>
            <template v-slot:[`item.created`]="{item}">
              <span class="text-right">{{item.created | dateTime}}</span>
            </template>
            <template v-slot:[`item.actions`]="{item}">
                <v-menu close-on-click close-on-content-click offset-y left :nudge-bottom="10" :nudge-left="10" transition="slide-y-transition">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="mr-n4" v-bind="attrs" v-on="on" text x-small><v-icon color="fontBody" size="24">mdi-dots-vertical</v-icon></v-btn>
                  </template>
                  <v-list class="pa-0">
                    <v-list-item class="px-3" v-if="$helpers.hasSomePermission(['delete_suppliergroup'])" @click="handleDestroy(item.id)" :ripple="false">
                      <v-list-item-title class="body-2">Eliminar del segmento</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
          </v-data-table>
          <v-row v-else>
            <v-col v-for="(supplier, index) in suppliersList" :key="`${supplier.id}-${index}`" cols="4" :md="$vuetify.breakpoint.width < 1200 ? 3 : 2">
              <v-hover v-slot="{ hover }">
                <v-card class="pa-5 rounded-md" :elevation="hover ? 5 : 0" flat @click.stop="$router.push({ name: 'SupplierRetrieveUpdate', params: { id: supplier.id }}), $store.commit('base/SET_QUERY', $route.query)">
                  <v-row no-gutters>
                    <v-icon color="primary">mdi-human-dolly</v-icon>
                    <v-spacer />
                    <v-menu v-model="menu[index]" close-on-click offset-y left :nudge-bottom="0" :nudge-left="10" transition="slide-y-transition" :min-width="152">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn class="mt-n1 mr-n2" text v-bind="attrs" v-on="on" :ripple="false">
                          <v-icon color="secondary" size="24">mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>
                      <v-list class="pa-0">
                        <v-list-item class="px-3" v-if="$helpers.hasSomePermission(['delete_suppliergroup'])" @click="handleDestroy(supplier.id)" active-class="transparent" :ripple="false">
                          <v-list-item-title class="body-2">Eliminar del segmento</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-row>
                  <span class="d-block mt-2">
                    <v-sheet class="transparent" height="25">
                      <span v-if="supplier.name.length" class="fontBody--text font-weight-medium body-1 d-inline-block text-truncate" style="max-width: 162px;">{{supplier.name}}</span>
                      <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
                    </v-sheet>
                  </span>
                  <span v-if="supplier.tax_id" class="mt-n2 d-block body-1 fontDraw--text d-inline-block text-truncate" style="max-width: 180px;">{{supplier.tax_id}}</span>
                  <span class="d-block mt-n1 body-2 text--disabled font-italic" v-else>Sin especificar</span>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
          <VMainPagination titleShowAll="Ver todos los proveedores" :count="count" redirect="SupplierListCreate" />
        </template>
      </template>
    </v-col>

     <!-- dialog confirm delete -->
     <v-dialog v-model="dialogDelete" width="709" persistent no-click-animation overlay-color="overlay">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar color="transparent" flat height="46">
            <v-toolbar-title class="px-5 subtitle-2 fontBody--text font-weight-semibold">Eliminar del segmento</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialogDelete=false" icon small retain-focus-on-click><v-icon color="fontBody" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-4 px-5 fontBody--text">
          <v-row class="pl-5" align="center" no-gutters>
            <v-col cols="3">
              <img :src="require(`@/assets/backgrounds/modal-segment-destroy.svg`)" :width="150" />
            </v-col>
            <v-col class="pl-6 pr-0">
              <span class="d-block">Al eliminar un proveedor del segmento, este quedará sin esa asociación. Es importante tener en cuenta que el proveedor en sí no será eliminado.</span>
              <span class="d-block mt-4 mb-3">Escribe <span class="font-weight-bold">“confirmar”</span> para continuar.</span>
              <v-text-field v-model="confirm" outlined single-line maxlength="9" dense hide-details />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-2 px-5">
          <v-spacer/>
          <v-btn @click="dialogDelete=false" outlined>Cancelar</v-btn>
          <v-btn color="primary" @click="destroySuppliertSegment" :disabled="confirm.toLowerCase() !== 'confirmar' ">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog confirm delete -->
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import VEmptyState from '@/components/commons/VEmptyState'
import MiniHeader from '@/components/commons/MiniHeader'
import VQueryBuilder from '@/components/VQueryBuilder/VQueryBuilder'
import SkeletonList from '@/components/commons/skeletonLoader/SkeletonList'
import VTableHeaders from '@/components/VTable/VTableHeaders'
import ListViewMixin from '@/mixins/ListViewMixin'
import GenericViewMixin from '@/mixins/GenericViewMixin'
import VMainPagination from '@/components/commons/VMainPagination'

export default {
  components: {
    VQueryBuilder,
    VTableHeaders,
    SkeletonList,
    VMainPagination,
    MiniHeader,
    VEmptyState
  },
  mixins: [
    GenericViewMixin,
    ListViewMixin
  ],
  data: () => ({
    menu: [false],
    idDelete: '',
    dialogDelete: false,
    activeFilter: false,
    mode: true,
    scroll: 0,
    confirm: '',
    isAccept: false,
    dialogConfirm: false,
    current: {},
    filters: [
      {
        type: 'text',
        id: 'name',
        label: 'Nombre',
        value: null,
        category: 'a',
        operators: ['contiene']
      },
      {
        type: 'text',
        id: 'tax_id',
        label: 'RUT',
        value: null,
        category: 'a',
        operators: [],
        countries: ['PE', 'CL']
      }
    ],
    headers: [
      { text: 'Proveedor', value: 'name', class: 'font-weight-bold', sortable: true },
      { text: 'RUT', value: 'tax_id', sortable: true },
      { text: 'Actividad económica', value: 'tax_settings', sortable: true },
      { text: 'Creado el', value: 'created', align: 'end', sortable: true },
      // { text: 'Lista', value: 'list', align: 'end', sortable: true },
      { text: '', value: 'actions', align: 'end'}
    ],
    dialogSupplier: false,
    loading: false,
    breadcrumbs: {
      main: 'Recepción',
      children: [
        {
          text: 'Segmentos',
          route: 'SuppliersGroupsList'
        },
        {
          text1: ''
        }
      ]
    }
  }),
  computed: {
    ...mapState({
      count: state => state.suppliers.suppliersCount,
      suppliersList: state => state.suppliers.suppliersList,
      countriesList: state => state.base.countriesList,
      membersList: state => state.accounts.membersList,
      groupsList: state => state.suppliers.groupsList
    }),
    heightTable () {
      if (this.$vuetify.breakpoint.height >= 800 && this.suppliersList.length < 17) return 'auto'
      return 'calc(100vh - 210px)'
    }
  },
  watch: {
    'newSupplier.country' () {
      this.newSupplier.state = ''
      this.newSupplier.tax_id = ''
    }
  },
  created () {
    this.getList()
  },
  methods: {
    handleDestroy (id = '') {
      this.idDelete = id
      this.dialogDelete = true
    },
    onScroll (e) {
      this.offsetTop = e.target?.scrollingElement?.scrollTop ?? 0
    },
    destroySuppliertSegment () {
      this.$store.dispatch('suppliers/UPDATE', {
        resource: 'suppliers/groups',
        id: this.$route.params.id,
        payload: {
          suppliers: this.suppliersList.filter(({id}) => id !== this.idDelete).map(({id}) => id)
        }
      })
      .then(() => {
        this.$dialog.message.info('El proveedor se ha sido eliminado con éxito')
        this.getList()
      })
      .catch((error) => {
        this.$dialog.message.error(this.parseErrors(error.response.data))
      })
      .finally(() => {
        this.idDelete = ''
        this.confirm = ''
        this.dialogDelete = false
      })
    },
    getList () {
      this.breadcrumbs.children[1].text1 = ''
      this.loading = true
      let currentQuery = JSON.parse(JSON.stringify(this.$route.query))
      if (currentQuery.name) delete currentQuery.name
      this.$store.dispatch('suppliers/LIST', {
        resource: 'suppliers',
        query: {
          groups__in: this.$route.params.id,
          ...currentQuery
        }
      })
      .finally(() => {
        this.loading = false
      })
      this.$store.dispatch('suppliers/RETRIEVE', {
        resource: 'suppliers/groups',
        id: this.$route.params.id
      })
      .then((response) => {
        this.breadcrumbs.children[1].text1 = response.data.name
      })
    }
  }
}
</script>